body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    line-height: 1.8;
    color: #333;
    background-color: #f0f2f5;
  }
  
  .App {
    margin: 0 auto;
  }

  p {
    max-width: 1200px;
  }
  
  .App-header {
    background-color: #343a40;
    color: white;
    padding: 60px 0px;
    text-align: center;
    background-size: cover;
    background-position: center;
    position: relative;
  }
  
  .App-header::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .App-header h1 {
    margin: 0;
    font-size: 3em;
    position: relative;
    z-index: 1;
  }
  
  .App-header .tagline {
    margin-top: 10px;
    font-size: 1.2em;
    color: #61dafb;
    position: relative;
    z-index: 1;
  }

  .header-image {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    margin: 20px 0;
  }
  
  .section-image {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  section {
    margin: 40px 0 0 0;
    background: white;
    padding: 40px 0 0 0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  section .content {
    max-width: 800px;
    margin: 0 auto;
    margin: 0 auto;
  }
  
  h2 {
    font-size: 2em;
    padding-left: 30px;
    padding-bottom: 15px;;
    color: #999b9e;
    margin: 0 auto;
    max-width: 1200px;
  }
  
  p, ul {
    font-size: 1.1em;
    margin: 0 auto;
    max-width: 1200px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 25px;
  }
  
  ul {
    padding-left: 120px;
    padding-right: 45px;
  }
  
  li {
    margin-bottom: 10px;
    margin: 0 auto;
    padding-left: 45px;
    padding-right: 45px;
  }
  
  footer {
    background-color: #343a40;
    color: white;
    padding: 40px 20px;
    text-align: center;
  }
  
  footer .content {
    margin-bottom: 20px;
  }
  
  footer .socials a {
    color: #61dafb;
    margin: 0 10px;
    text-decoration: none;
  }
  
  footer .socials a:hover {
    text-decoration: underline;
  }
  
  footer p {
    font-size: 0.9em;
  }
  
  footer a {
    color: #61dafb;
    text-decoration: none;
  }
  
  footer a:hover {
    text-decoration: underline;
  }
  